import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';


export default function TicketPopover({popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpen}) {
    return (
      <div>
        <Popover
          id={popperId}
          open={openPopper}
          anchorEl={anchorEl}
          onClose={handleClosePopOver}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          
        >
            <Stack sx={{
                padding: "10px",
                alignContent: "left"
            }}>
                <Button sx={{
                    color: "black",
                    padding: "10px",
                }}
                onClick={handleClickOpen}
                >View Details</Button>
                <Divider />
                <Button sx={{
                    color: "black",
                    padding: "10px"
                }}>Update Status</Button>
                <Divider />
                <Button sx={{
                    color: "red",
                    padding: "10px"
                }}>Hide Ticket</Button>
                <Divider />
            </Stack>
        </Popover>
      </div>
    );
  }