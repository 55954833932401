import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from "@mui/material";

export default function RentalsForm(props) {
  return (
    <Grid container
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid item xs={12} spacing={2}>
        <TextField
            required
            id="outlined-required"
            label="Rental Name"
            onChange={props.handleRentalNameChange}
            fullWidth={true}
        />
      </Grid>
        
      <Grid item xs={12} spacing={2}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rental Type</InputLabel>
            <Select
            labelId="rental-type"
            id="rental-type-id"
            value={props.rentalType}
            label="Age"
            onChange={props.handleChangeRentalType}
            >
                <MenuItem value="REGULAR">REGULAR</MenuItem>
                <MenuItem value="CONDOMINIUM">CONDOMINIUM</MenuItem>
                <MenuItem value="SEMI-DETACHED">SEMI DETACHED</MenuItem>
            </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={2}>
        <TextField
          required
          id="outlined-required"
          label="Rental Location"
          onChange={props.handleRentalLocationChange}
        />
      </Grid>
    </Grid>
  );
}